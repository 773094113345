// Views will go here
import React, { ReactElement, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { IconNames } from '@hallmark/web.core.buttons.icon-button';
import { SEO } from '@hallmark/web.page-components.seo';
import { useCustomBordersAndShadows } from '../../components/canvas-custom-config/hooks/useCustomBordersAndShadows';
import { DrawerContainer } from '../../components/card-controls/drawer-container';
import { CardEditor } from '../../components/card-editor';
import { CardSelectBar } from '../../components/card-select-bar';
import { Layout } from '../../components/layout';
import { useAppContext } from '../../context/app-context';
import { hideLoadingScreen, setIsProjectSaved } from '../../context/app-context/app-context-actions';
import { useCardContext, resetCardDataAction } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import { RegionalCodesList } from '../../global-types';
import {
  useActiveCanvas,
  useLineItemUUID,
  useExitUrl,
  useSanitizeSearchUrl,
  useFeatureFlags,
  useIsSignAndSend,
  useNavigateBlock,
  useSaveProject,
} from '../../hooks';
import { useHandleIndexChange } from '../../hooks/useHandleIndexChange';
import { config } from '../../regional-config';
import { isToolbarVisible } from '../../utils';
import { isAnyOfRegions } from '../../utils/utility';
import styles from './editor.module.scss';
import { useStartProject } from './hooks';
import { useEditorNavigation } from './hooks/useEditorNavigation';
import { getClassEditorOverlapStyle } from './utils/get-class-editor-overlap-style';

export const EditorView = (): ReactElement => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { cardState, cardDispatch } = useCardContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const canvasParentRef = useRef<HTMLDivElement>(null);
  const slideIndex = cardState.slideCardIndex;
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);
  const [dimensions, setDimensions] = useState({
    workspaceHeight: 0,
    footerHeight: 0,
  });
  const [triggerPageLoadTracking, setTriggerPageLoadTracking] = useState(false);
  const isDataLoaded = useRef<boolean>(false);
  const { t } = useTranslation();
  const activeCanvas = useActiveCanvas();
  const isSignAndSend = useIsSignAndSend();
  const { saveProject } = useSaveProject();
  const [isEditorToolbarVisible, setIsEditorToolbarVisible] = useState(false);
  const textEditorDrawerType = config?.cardEditor?.textEditorDrawerType;
  const footerContainerClass = config?.cardSelectBar?.containerClass;
  const handleIndexChange = useHandleIndexChange({ savingCard: true });
  if (config?.editor?.hasCustomBordersAndShadows) {
    useCustomBordersAndShadows((activeCanvas && activeCanvas.current) || null);
  }

  const {
    appState: {
      isProjectSaved,
      isSystemErrorOpen,
      cardFacesLoaded,
      isTextDrawerOpen,
      isImageUploadDrawerOpen,
      isImageUploadToPhotoZoneOpen,
    },
    appDispatch,
  } = useAppContext();

  const isCreateView = useMemo(() => pathname.indexOf('create') >= 0, [pathname]);
  const pageTitle = useMemo(
    () => (isCreateView ? t('editorView.pageTitle') : t('editorView.editPageTitle')),
    [isCreateView],
  );
  const { DYNAMIC_TEXBOX_DRAWER, SAS_TOOLBAR_TOGGLE, SKIP_PREVIEW_STEP } = useFeatureFlags();
  useLineItemUUID();
  useExitUrl();
  const { isSearchUrlSanitized } = useSanitizeSearchUrl();

  const currentCardFace = cardState.cardFacesList[cardState.activeCardIndex];
  const isFrontFace = currentCardFace?.type === 'front';
  const activeCanvasObject = activeCanvas?.current?.getActiveObject();

  const editableFaces = useMemo(
    () => cardState.cardFacesList.filter((face) => face.editorDisplayIndicator),
    [cardState.cardFacesList],
  );
  const { buttonProps } = useEditorNavigation();

  // TODO: Move this to a custom hook to clean the component
  /**
   * First function to be called when user open the page, which
   * kicks off loading of project assets
   */
  const startProject = useStartProject(isDataLoaded, [pathname, isSearchUrlSanitized]);

  const onChangeIndex = (newIndex: number) => {
    if (newIndex === editableFaces.length - 1) {
      const newActiveCardIndex = editableFaces[slideIndex + 1]?.faceNumber - 1;
      handleIndexChange(newIndex, newActiveCardIndex);
    } else {
      handleIndexChange(newIndex);
    }
  };

  const handleClickOutsideCanvas = (ev: MouseEvent | TouchEvent) => {
    const target = ev.target as HTMLElement;
    if (target?.nodeName !== 'CANVAS') {
      activeCanvas?.current?.discardActiveObject().requestRenderAll();
    }
  };

  const calculateDimensions = () => {
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    if (viewportWidth < 1140) {
      const headerHeight = 88;
      const menuHeight = 147;
      const footerHeight = 100;
      const workspaceHeight = viewportHeight - headerHeight - menuHeight - footerHeight;
      setDimensions({ workspaceHeight, footerHeight });
    } else {
      const headerHeight = 100;
      const footerHeight = 108;
      const workspaceHeight = viewportHeight - headerHeight - footerHeight;
      setDimensions({ workspaceHeight, footerHeight });
    }
  };

  const handleBrowserNavigation = useCallback(
    (nextLocation: Location, action: string) => {
      if (action === 'POP' && !isProjectSaved) {
        saveProject({
          shouldRestoreCanvas: true,
          isSaveButtonClicked: false,
          generatePreviews: false,
        })?.then(() => {
          setIsProjectSaved(appDispatch, true);
          navigate(nextLocation.pathname + nextLocation.search, { replace: true });
        });
        return false;
      }
    },
    [isProjectSaved, saveProject, navigate],
  );

  if (SKIP_PREVIEW_STEP) useNavigateBlock(handleBrowserNavigation);

  useEffect(() => {
    if (sessionStorage.getItem('redirectingFromPOD')) {
      hideLoadingScreen(appDispatch);
      sessionStorage.removeItem('redirectingFromPOD');
    }
  }, [appDispatch]);

  useEffect(() => {
    calculateDimensions();
    window.addEventListener('resize', calculateDimensions);
    return () => {
      window.removeEventListener('resize', calculateDimensions);
    };
  }, []);

  useEffect(() => {
    if (isDataLoaded.current) {
      return;
    }
    // reset card state re-load canvas if moving back to edit
    resetCardDataAction(cardDispatch);
    // initialize or load project and save the response in corresponding context
    startProject();
  }, [startProject, isDataLoaded.current]);

  useEffect(() => {
    // We save this so we can load the project from the address view if the page is refreshed
    sessionStorage.setItem('lastProjectLoaded', initializedData?.project_id || '');
  }, [initializedData?.project_id]);

  useEffect(() => {
    // Additional saved info in case of address view refresh
    sessionStorage.setItem('lastProjectTypeCode', initializedData?.project_type_code || '');
  }, [initializedData?.project_type_code]);

  useEffect(() => {
    if (cardFacesLoaded) {
      hideLoadingScreen(appDispatch);
    }
  }, [cardFacesLoaded]);

  useEffect(() => {
    setTriggerPageLoadTracking(true);
  }, []);

  useEffect(() => {
    if (initializedData?.product_id && isInitialPageLoad && triggerPageLoadTracking) {
      setIsInitialPageLoad(false);
    }
  }, [initializedData, isInitialPageLoad, triggerPageLoadTracking, isCreateView]);

  useEffect(() => {
    const clickOrTouch = 'ontouchstart' in window ? 'touchstart' : 'click';
    canvasParentRef?.current?.addEventListener(clickOrTouch, handleClickOutsideCanvas);
    if (activeCanvas) {
      setIsEditorToolbarVisible(isToolbarVisible(activeCanvas, isSignAndSend));
    }
    return () => canvasParentRef?.current?.removeEventListener(clickOrTouch, handleClickOutsideCanvas);
  }, [activeCanvas]);

  const seoMetaProperties = [
    {
      property: 'og:title',
      content: pageTitle,
    },
    {
      property: 'og:site_name',
      content: pageTitle,
    },
  ];

  /** Get the class for the editor based on the drawer state */
  const editorClass = getClassEditorOverlapStyle(
    styles,
    isTextDrawerOpen,
    activeCanvasObject,
    isMobile,
    isFrontFace,
    isImageUploadDrawerOpen,
    isImageUploadToPhotoZoneOpen,
    textEditorDrawerType,
    DYNAMIC_TEXBOX_DRAWER,
  );

  return (
    <Layout hideToolbar={!isEditorToolbarVisible && SAS_TOOLBAR_TOGGLE}>
      <SEO title={pageTitle} description={`${t('editorView.seo')}`} metaProperties={seoMetaProperties} />
      <div className={editorClass}>
        <div
          className={styles['workspace-panel']}
          data-testid="Workspace"
          id="Workspace"
          ref={canvasParentRef}
          style={{
            height: `${dimensions.workspaceHeight}px`,
          }}
        >
          <CardEditor cardFaceIndex={slideIndex} />
        </div>
        <div
          className={classNames(styles[footerContainerClass ?? 'footer'])}
          style={{
            height: `${dimensions.footerHeight}px`,
          }}
        >
          {cardState?.cardFacesList && (
            <div className={styles['card-select-bar']}>
              <CardSelectBar
                faces={editableFaces}
                slideIndex={slideIndex}
                handleIndexChange={onChangeIndex}
                mode="edit"
              />
            </div>
          )}
          {!isSystemErrorOpen && isAnyOfRegions([RegionalCodesList.uk, RegionalCodesList.us]) && (
            <Button
              {...buttonProps}
              endIcon={{ name: IconNames.ArrowsTailRightBold, color: 'white' }}
              mode={ButtonModes.Primary}
              testId="address-button"
              addClass={styles['address-button']}
            />
          )}
        </div>
      </div>
      <DrawerContainer />
    </Layout>
  );
};
