import { fabric } from 'fabric';
import { isTextbox } from './helper-settings';

/**
 * Sets up borders and shadows for a given fabric object on the canvas. This function creates
 * a rectangular border and shadow around the object, which can be updated dynamically based on the
 * object's transformations (e.g., moving, scaling, rotating). The borders and shadows are added
 * to the canvas only when the object is selected, and removed when it is deselected.
 *
 * @param {fabric.Object} fabricObject - The fabric object around which the border and shadow will be applied.
 * @param {fabric.Canvas} canvas - The fabric canvas instance on which the object resides.
 */
export const setupBordersForObject = (fabricObject: fabric.Object, canvas: fabric.Canvas): void => {
  // Check if the object is a textbox or an image
  if (fabricObject.type !== 'textbox' && fabricObject.type !== 'image') {
    return;
  }

  // Settings
  const isText = isTextbox(fabricObject);
  let PADDING = isText ? 14 : 20;
  const SHADOW_OFFSET = 2;
  const BORDER_COLOR = '#ED3E6C';
  const SHADOW_COLOR = '#fff';

  // Create a border rectangle and shadow rectangle based on the object's dimensions and position
  const borderRect = new fabric.Rect({
    left: fabricObject.left ? fabricObject.left - PADDING : undefined,
    top: fabricObject.top ? fabricObject.top - PADDING : undefined,
    width: fabricObject.getScaledWidth() + PADDING * 2,
    height: fabricObject.getScaledHeight() + PADDING * 2,
    stroke: BORDER_COLOR,
    strokeWidth: 5,
    strokeDashArray: [10, 14],
    fill: 'transparent',
    selectable: false,
    evented: false,
  });

  const borderShadow = new fabric.Rect({
    left: borderRect.left ? borderRect.left + SHADOW_OFFSET : undefined,
    top: borderRect.top ? borderRect.top + SHADOW_OFFSET : undefined,
    width: borderRect.width,
    height: borderRect.height,
    stroke: SHADOW_COLOR,
    strokeWidth: 5,
    strokeDashArray: [10, 14],
    fill: 'transparent',
    selectable: false,
    evented: false,
  });

  // Function to update and show the borders
  const updateAndShowBorders = (): void => {
    updateBorderObjects();
    borderRect.visible = true;
    borderShadow.visible = true;
    canvas.add(borderShadow, borderRect);
  };

  const resetPadding = (): void => {
    borderRect.left = fabricObject.left ? fabricObject.left : undefined;
    borderRect.top = fabricObject.top ? fabricObject.top : undefined;
    borderRect.width = fabricObject.getScaledWidth() * 2;
    borderRect.height = fabricObject.getScaledHeight() * 2;
  };

  // Function to hide the borders
  const hideBorders = (): void => {
    borderRect.visible = false;
    borderShadow.visible = false;
    canvas.remove(borderShadow, borderRect);
  };

  const updateBorderObjects = () => {
    const centerPoint = fabricObject.getCenterPoint();
    PADDING = fabricObject.padding !== undefined ? fabricObject.padding * 2 : isTextbox(fabricObject) ? 14 : 20;

    borderRect.set({
      left: centerPoint.x,
      top: centerPoint.y,
      width: fabricObject.getScaledWidth() + PADDING * 2,
      height: fabricObject.getScaledHeight() + PADDING * 2,
      originX: 'center',
      originY: 'center',
      angle: fabricObject.angle,
      data: {
        type: 'border',
      },
    });

    borderShadow.set({
      left: centerPoint.x + SHADOW_OFFSET,
      top: centerPoint.y + SHADOW_OFFSET,
      width: fabricObject.getScaledWidth() + PADDING * 2,
      height: fabricObject.getScaledHeight() + PADDING * 2,
      originX: 'center',
      originY: 'center',
      angle: fabricObject.angle,
      data: {
        type: 'border',
      },
    });
  };

  // Register event handlers for the object
  fabricObject.on('selected', () => {
    updateAndShowBorders();
  });

  fabricObject.on('deselected', () => {
    hideBorders();
  });

  fabricObject.on('moving', () => {
    updateBorderObjects();
  });
  fabricObject.on('scaling', () => {
    updateBorderObjects();
  });
  fabricObject.on('rotating', () => {
    updateBorderObjects();
  });
  fabricObject.on('resizing', () => {
    updateBorderObjects();
  });

  if (isText) {
    fabricObject.on('changed', () => {
      updateBorderObjects();
    });

    fabricObject.on('text:changed', updateBorderObjects);
  }

  fabricObject.on('custom:updateBorders', updateBorderObjects);

  fabricObject.on('custom:hideBorders', () => {
    hideBorders();
  });

  fabricObject.on('custom:resetPadding', () => {
    resetPadding();
  });

  const cleanupBorders = () => {
    fabricObject.off('selected');
    fabricObject.off('deselected');
    fabricObject.off('moving');
    fabricObject.off('scaling');
    fabricObject.off('rotating');
    fabricObject.off('resizing');
    if (isText) {
      fabricObject.off('changed');
      fabricObject.off('text:changed');
    }
    fabricObject.off('custom:updateBorders');
    fabricObject.off('custom:hideBorders');
    fabricObject.off('custom:resetPadding');
  };

  canvas.on('object:removed', (e) => {
    if (e.target === fabricObject) {
      cleanupBorders();
    }
  });
};
